import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const Donate = () => {
    const seo = {
        metaDesc: 'Donate - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Donate - Inventiv.org" canonical='/contact' seo={seo} />

            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>Donate</h1>
                    </div>
                </div>
            </div>

            <div class="container mt-3">
                <p class="sec-clr text-center f-16">
                    Join us at Inventiv.org and let's reimagine what's possible. Together, we have the power to shape a future that is not only innovative but also sustainable, inclusive, and full of endless possibilities. The journey starts here, and we invite you to be a part of it.
                </p>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 bg-light-blue wow fadeInUp delay-04s">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-4 ">
                            <img src="assets/img/banner/meeting.webp" width="100%" alt=""/>
                                <h3 class="mt-1 sec-clr bold">
                                    Support Global Initiatives
                                </h3>
                                <p class="sec-clr f-16">
                                    Inventiv.org works globally, collaborating with inventors, organizations, and communities across borders. Your donation helps us support inventors in underserved areas, drive systemic change through partnerships, and address pressing global challenges. Together, we can create a more sustainable and equitable world through the power of invention.
                                </p>
                        </div>

                        <div class="col-12 col-md-12 col-lg-8">
                            <div class="row">
                                <div class="col-12 ">
                                    <p class="sec-clr f-16">
                                        Donate to Inventiv.org and empower inventors to change the world. Your contribution fuels innovation, fosters creativity, and drives positive change. Join us in our mission to inspire and support the inventors who are shaping a better future for all. Here’s how your donation can make a difference:
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 ">
                                    <h3 class="sec-clr bold">
                                        Fuel Invention
                                    </h3>
                                    <p class="sec-clr f-16">
                                        Your financial support enables us to provide inventors with the resources they need to turn their ideas into reality. From educational programs and mentorship initiatives to collaborative spaces and funding opportunities, your donation fuels innovation and empowers inventors to create breakthrough solutions to global challenges.
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 ">
                                    <h3 class="sec-clr bold">
                                        Amplify Impact
                                    </h3>
                                    <p class="sec-clr f-16">
                                        By donating to Inventiv.org, you amplify the impact of inventors worldwide. Your contribution helps us expand our reach, engage more inventors, and support their inventive journeys. Together, we can accelerate progress, solve complex problems, and build a future that is driven by innovation and positive change.
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 ">
                                    <h3 class="sec-clr bold">
                                        Foster a Culture of Creativity
                                    </h3>
                                    <p class="sec-clr f-16">
                                        Your donation fosters a culture of creativity and invention. By supporting Inventiv.org, you contribute to a community that celebrates and nurtures inventors, inspiring the next generation of innovative thinkers. Your generosity cultivates an environment where creativity thrives and inventive ideas flourish.
                                    </p>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 ">
                                    <h3 class="sec-clr bold">
                                        Be Part of Something Bigger
                                    </h3>
                                    <p class="sec-clr f-16">
                                        When you donate to Inventiv.org, you become part of a movement dedicated to harnessing the power of invention for the greater good. Your contribution connects you to a global network of inventors, experts, and supporters who are driving innovation and making a positive impact. Together, we can shape a future where creativity knows no limits.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section class="mt-3 mb-3 pt-3 pb-3">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                            <img src="assets/img/banner/two-women-are-smiling.webp" width="100%" alt=""/>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                            <p class="sec-clr f-16">
                                Your generosity matters. Whether it’s a one-time donation or a recurring contribution, your support empowers inventors and helps build a better world through innovation. Join us in our mission to unlock the potential of inventors worldwide. Donate to Inventiv.org today and be a catalyst for change. Together, let’s inspire, empower, and unleash the inventors who will shape the future. Thank you for your support.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default Donate;